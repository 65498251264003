
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["q", "archived", "context"]

  connect() {
  }

  launch() {

    const q = this.qTarget.value
    const url = this.data.get("url")
    let searchData = ""

    if (q.length > 0) {
      searchData += $("#search").serialize()
    }

    if (this.hasArchivedTarget) {
      const archived = this.archivedTarget.checked
      if (archived) {
        searchData += '&archived=1'
      } else {
        searchData += '&archived=0'
      }
    }

    if (this.hasContextTarget) {
      const context = this.contextTarget.value
      if (context) {
        searchData += '&context=' + context
      }
    }

    $.get({
      url: url,
      data: searchData,
      success: null,
      dataType: 'script'
    });

    return true
  }

}
