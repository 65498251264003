import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["list"]


    connect() {
        console.log("-- appointments controller connected --")
    }


    toggle(event) {
        const showPast = event.target.checked

        let url = `/event_appointments?show_past=${showPast}`
        const searchInput = document.getElementById('search')
        if (searchInput && searchInput.value.length > 1) {
            url += `&search=${searchInput.value}`
        }

        fetch(url, {
            headers: {
                'Accept': 'text/vnd.turbo-stream.html',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => response.text())
            .then(html => {
                Turbo.renderStreamMessage(html)
            })
    }
} 