import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        id: Number,
        userId: Number
    }

    connect() {
        console.log("EventSignatureController connected")
    }

    sendSignatureRequest(event) {
        event.preventDefault()

        fetch('/event_signatures/broadcast', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
            },
            body: JSON.stringify({
                reservation_id: this.idValue,
                user_id: this.userIdValue
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de l\'envoi de la signature')
                }
            })
            .catch(error => {
                console.error('Erreur:', error)
            })
    }
} 
